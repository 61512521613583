@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";

.button {
  font-family: "founders-grotesk-regular";
  font-weight: normal;

  display: inline-flex;

  align-items: center;

  padding: functions.rem(3px) 0;
  font-size: functions.rem(28px);
}

.buttonActive {
  @extend .button;

  text-decoration: underline;
}

@include breakpoints.regular() {
  .button {
    padding: functions.rem(20px);

    &:hover {
      color: colors.$green;
    }
  }
}

@use "styles/colors";
@use "styles/variables";
@use "styles/mixins";
@use "styles/breakpoints";

.container {
  @include mixins.spacerLarge(padding-top);
  @include mixins.spacerLarge(padding-bottom);
}

.paragraph {
  @include mixins.fontHeadingLarge();
  @include mixins.spacerSmall(padding-bottom);
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.listItem {
  margin: 0;
}

.assets {
  @include mixins.spacerMedium(margin-top);
}

@include breakpoints.regular() {
  .assets {
    @include mixins.spacerMediumDouble(margin-top);
  }
}

@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";

.button {
  position: relative;
  display: block;
  width: functions.rem(289px);
  height: functions.rem(192px);
}

.regular {
  width: 100%;
  height: 100%;

  padding: functions.rem(24px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: colors.$white;
  background-color: colors.$green;
}

.hover {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: functions.rem(24px);

  display: none;
  flex-direction: column;
  justify-content: space-between;

  color: colors.$black;
  background-color: colors.$white;

  transition: clip-path 300ms cubic-bezier(0.42, 0, 0.58, 1);
}

.icon {
  position: absolute;
  bottom: functions.rem(24px);
  right: functions.rem(24px);
  fill: currentColor;
}

.eyebrow {
  display: block;
  text-transform: uppercase;
  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(14px);
}

.title {
  display: block;

  font-family: "founders-grotesk-regular";
  font-weight: normal;
  font-size: functions.rem(32px);
  line-height: functions.rem(32px);
  white-space: pre-line;
}

@include breakpoints.regular() {
  .button {
    width: functions.rem(470px);
    height: functions.rem(272px);

    .hover {
      clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
    }

    &:hover {
      .hover {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .regular {
    padding: functions.rem(32px);
  }

  .hover {
    display: flex;
    padding: functions.rem(32px);
  }

  .icon {
    position: absolute;
    bottom: functions.rem(32px);
    right: functions.rem(32px);
    fill: currentColor;
  }

  .title {
    display: block;

    font-family: "founders-grotesk-regular";
    font-weight: normal;
    font-size: functions.rem(44px);
    line-height: functions.rem(48px);
    white-space: pre-line;
  }
}

@use "styles/colors";
@use "styles/mixins";
@use "styles/variables";
@use "styles/functions";
@use "styles/breakpoints";

.container {
  @include mixins.spacerSmall(padding-top);
  @include mixins.spacerMedium(padding-bottom);

  color: colors.$white;
  background-color: colors.$black;
}

.heading {
  @include mixins.spacerMedium(margin-bottom);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: functions.rem(24px) functions.rem(16px);
}

.listItem {
  width: 100%;
  margin: 0;
  padding: 0;
}

@include breakpoints.regular() {
  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: functions.rem(56px) functions.rem(16px);
  }
}

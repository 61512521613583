@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";
@use "styles/mixins";
@use "styles/variables";

.pill {
  display: inline-block;

  padding: 0 functions.rem(10px);
  height: functions.rem(24px);

  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(10px);
  line-height: functions.rem(24px);
  color: colors.$mid-grey;
  background-color: colors.$white;

  border-radius: variables.$rounded-100;
  border: 0;
}

.pillOnGrey {
  @extend .pill;

  background-color: colors.$light-grey;
  color: colors.$grey;
}

.pillGrey {
  @extend .pill;

  color: colors.$grey;
}

.pillPurple {
  @extend .pill;

  color: colors.$purple;
}

@use "styles/mixins";
@use "styles/functions";

.text {
  @include mixins.fontBodyLarge;

  white-space: pre-wrap;
}

.textIndent {
  @include mixins.fontBodyLarge;
  text-indent: functions.rem(42px);

  white-space: pre-wrap;
}

@use "styles/colors";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/functions";

.container {
  height: calc(100vh - #{functions.rem(variables.$height-navigation-mobile)});
  height: calc(
    var(--vh-orientation, 1vh) * 100 - #{functions.rem(variables.$height-navigation-mobile)}
  );
  min-height: functions.rem(400px);
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-color: colors.$black;
}

.list {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  right: functions.rem(-24px);
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;
}

.listItem {
  margin: 0;

  border-bottom: 2px solid #0f946b;

  &:last-child {
    border-bottom: none;
  }
}

.payoff {
  display: none;
  position: absolute;
  bottom: 32px;

  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(14px);
  line-height: functions.rem(16px);
  text-transform: uppercase;
  color: colors.$light-grey;
  white-space: pre-line;

  opacity: 0;

  // &::before {
  //   content: ">\A>\A>\A\A";
  // }
}

@include breakpoints.regular() {
  .container {
    min-height: 500px;
    height: calc(100vh - #{functions.rem(variables.$height-navigation-desktop)});
    height: calc(
      var(--vh-orientation, 1vh) * 100 - #{functions.rem(variables.$height-navigation-desktop)}
    );
  }

  .canvas {
    min-height: 500px;
  }

  .list {
    flex-direction: row;
    right: functions.rem(-40px);
  }

  .listItem {
    margin: 0;

    border-bottom: none;
    border-right: 2px solid #0f946b;

    &:last-child {
      border-right: none;
    }
  }

  .payoff {
    display: block;
  }
}

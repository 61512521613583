@use "styles/colors";
@use "styles/variables";
@use "styles/functions";
@use "styles/mixins";
@use "styles/breakpoints";

.container {
  @include mixins.spacerLarge(margin-bottom);
}

.columns {
  display: flex;
  flex-direction: column;

  gap: functions.rem(24px);
}

.asset {
  overflow: hidden;
  border-radius: variables.$rounded-20;
  will-change: transform;
}

@include breakpoints.regular() {
  .columns {
    display: flex;
    flex-direction: row;

    gap: functions.rem(16px);

    > * {
      width: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .asset {
    border-radius: variables.$rounded-40;
  }
}

@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";
@use "styles/mixins";

.button {
  display: flex;
  width: 100%;
  gap: functions.rem(16px);

  flex-direction: column;

  padding: functions.rem(20px) 0;
}

.title {
  @include mixins.fontBodyLarge();
  font-size: functions.rem(28px);
  line-height: functions.rem(32px);
}

.list {
  display: flex;
  padding: 0;
  list-style: none;
  gap: functions.rem(8px);
  flex-grow: 0;
  flex-shrink: 0;
}

.listOne {
  @extend .list;
}

.listTwo {
  @extend .list;
  justify-content: flex-end;
}

.listItem {
  margin: 0;
}

@include breakpoints.regular() {
  .button {
    padding: functions.rem(20px);

    gap: functions.rem(24px);

    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    flex-direction: row;

    padding: functions.rem(24px);

    &:hover {
      color: colors.$green;
      // background-color: rgba(colors.$white, 0.3);
    }

    .title {
      width: 40%;
    }

    .listOne {
      width: 30%;
    }

    .listTwo {
      width: 30%;
    }
  }

  .title {
    @include mixins.fontBodyLarge();
  }
}

@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.content {
  flex-grow: 0;
  flex-shrink: 0;

  padding: 0;
}

.imageWrapper {
  flex-grow: 0;
  flex-shrink: 0;

  min-height: 300px;
  background-color: colors.$black;
  border-radius: variables.$rounded-20;
  overflow: hidden;

  margin-bottom: functions.rem(30px);
}

.heading {
  margin-bottom: functions.rem(13px);
}

.copy {
  margin-bottom: functions.rem(23px);
}

.list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.listItem {
  @include mixins.fontBodySmall();

  display: block;
  padding: functions.rem(14px) functions.rem(16px);
  margin-bottom: functions.rem(8px);
  background-color: colors.$white;
  white-space: pre-line;
  border-radius: variables.$rounded-4;

  &:last-child {
    margin-bottom: 0;
  }
}

.dot {
  position: absolute !important;
  left: calc(50% - #{functions.rem(16px)});
  display: block;
  width: functions.rem(32px) !important;
  height: functions.rem(32px) !important;
  background-color: colors.$purple;
  border-radius: variables.$rounded-100;
  content: "";
  display: none;

  flex-grow: 0;
  flex-shrink: 0;
}

@include breakpoints.regular() {
  .content {
    padding: 0 functions.rem(60px);
  }

  .imageWrapper {
    margin-bottom: 0;
    border-radius: variables.$rounded-40;
  }

  .heading {
    margin-bottom: functions.rem(30px);
  }

  .copy {
    margin-bottom: functions.rem(36px);
  }

  .list {
    margin: 0;
  }

  .listItem {
    padding: functions.rem(16px) functions.rem(18px);
    margin-bottom: functions.rem(16px);
  }

  .dot {
    display: block;
  }
}

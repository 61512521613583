@use "styles/colors";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/functions";
@use "styles/mixins";

.container {
  color: colors.$white;
  background-color: colors.$black;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: functions.rem(44px) 0 functions.rem(70px);
  padding: 0;
}

.menuItem {
  display: flex;
  align-items: center;
}

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  display: flex;
  align-items: center;
}

.final {
  @include mixins.fontLabel();

  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: functions.rem(10px);
  width: 100%;
  justify-content: space-between;
  margin-bottom: functions.rem(24px);
}

.payoff {
  font-size: functions.rem(14px);
  font-weight: normal;
}

@include breakpoints.regular() {
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    height: functions.rem(320px);
  }

  .menuItem {
    display: flex;
    align-items: center;
  }

  .final {
    flex-direction: row;
    margin-bottom: functions.rem(32px);
  }
}

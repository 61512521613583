@use "styles/colors";
@use "styles/mixins";
@use "styles/variables";
@use "styles/functions";
@use "styles/breakpoints";

.container {
  @include mixins.spacerSmall(margin-top);
  @include mixins.spacerLarge(margin-bottom);
}

.divider {
  @include mixins.spacerLarge(margin-bottom);
}

.listWrapper {
  position: relative;
  width: 100%;
}

.eyebrow {
  text-align: center;

  h1 {
    margin-bottom: functions.rem(30px);

    &:after {
      content: " //";
    }
  }
}

.heading {
  @include mixins.spacerSmall(margin-bottom);
  text-align: center;
  max-width: functions.rem(900px);
  margin-left: auto;
  margin-right: auto;
}

.body {
  @include mixins.spacerLarge(margin-bottom);
  text-align: center;
  max-width: functions.rem(672px);
  margin-left: auto;
  margin-right: auto;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  display: flex;
  flex-direction: column-reverse;

  margin-bottom: functions.rem(60px);

  &:last-child {
    margin-bottom: 0;
  }
}

@include breakpoints.regular() {
  .listWrapper {
    position: relative;
    width: 100%;

    &:before {
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      height: 100%;
      max-height: 100%;
      width: 2px;
      background-image: url("/assets/images/dashed-line-mid-grey.gif");
      content: "";
    }
  }

  .line {
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    height: 100%;
    max-height: 100%;
    width: 2px;
    background-image: url("/assets/images/dashed-line-purple.gif");
  }

  .listItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    width: 100%;
    margin: 0 0 functions.rem(16px);
    padding: 0;

    > * {
      position: relative;
      width: calc(50% - #{functions.rem(44px)});
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &:first-child,
    &:last-child {
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        background-color: colors.$background;
        height: 50%;
        width: 100%;
        content: "";
      }
    }
    &:last-child {
      &:before {
        top: 50%;
      }
    }
  }
}

@use "styles/mixins";
@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.imageWrapper {
  @include mixins.spacerExtraSmall(margin-bottom);

  position: relative;
  overflow: hidden;
  border-radius: variables.$rounded-20;
  will-change: transform;

  &:last-child {
    margin-bottom: 0;
  }
}

.imagesWrapper {
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.imagesWrapperThree {
  display: inline-block;

  &:before {
    display: inline-block;
    width: functions.rem(155px);
    margin-right: functions.rem(16px);
    margin-bottom: functions.rem(16px);
    vertical-align: top;
    content: "";
  }
}

.imageWrapper {
  font-size: 0;
  line-height: 0;

  .imagesWrapperThree & {
    width: 100%;

    &:first-child {
      vertical-align: top;
      display: inline-block;
      width: functions.rem(156px);
      margin-bottom: functions.rem(16px);
      z-index: 1;
    }

    &:nth-child(2) {
      vertical-align: top;
      display: inline-block;
      position: relative;
      width: functions.rem(155px);
      margin-right: functions.rem(16px);
      margin-top: functions.rem(-110px);
      margin-bottom: 0;

      > * {
        width: functions.rem(156px) !important;
        border-radius: variables.$rounded-20;
        will-change: transform;
      }
    }

    &:nth-child(3) {
      vertical-align: top;
      display: inline-block;
      width: functions.rem(156px);
      margin-bottom: 0;
    }
  }
}

@include breakpoints.regular() {
  .imagesWrapperOne {
  }

  .imagesWrapperTwo {
    display: flex;
    align-items: flex-start;
    gap: functions.rem(16px);
    justify-content: space-between;
  }

  .imagesWrapperThree {
    display: flex;
    align-items: flex-start;
    gap: functions.rem(16px);
    justify-content: space-between;

    &:before {
      display: none;
    }
  }

  .imageWrapper {
    position: relative;
    margin-bottom: 0;
    border-radius: functions.rem(variables.$rounded-40);

    > * {
      overflow: hidden;
    }

    .imagesWrapperOne & {
      width: functions.rem(1130px);
      float: right;
    }

    .imagesWrapperTwo & {
      width: 100%;

      &:first-child {
        margin-top: functions.rem(90px);
      }
    }

    .imagesWrapperThree & {
      width: 100%;

      flex-grow: 0;
      flex-shrink: 0;

      &:first-child {
        display: block;
        width: functions.rem(328px);
        margin: 0;
        margin-top: functions.rem(120px);
        z-index: 1;
      }

      &:nth-child(2) {
        position: relative;
        display: block;
        width: functions.rem(442px);
        margin: 0;
        margin-top: functions.rem(290px);
        overflow: visible;

        > * {
          width: functions.rem(558px) !important;
          left: functions.rem(-116px);
          border-radius: variables.$rounded-40;
          will-change: transform;
        }
      }

      &:nth-child(3) {
        margin: 0;
        display: block;
        width: functions.rem(558px);
      }
    }
  }
}

@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";
@use "styles/mixins";
@use "styles/variables";

.button {
  display: flex;
  align-items: center;

  padding: 0 functions.rem(6px) 0 functions.rem(12px);
  height: functions.rem(32px);

  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(12px);
  line-height: functions.rem(32px);
  color: colors.$grey;
  background-color: colors.$white;

  border-radius: variables.$rounded-100;
  border: 0;

  cursor: pointer;

  svg {
    stroke: currentColor;
    width: functions.rem(24px);
    height: functions.rem(24px);
  }
}

@include breakpoints.regular() {
  .button {
    &:hover {
      color: colors.$white;
      background-color: colors.$mid-grey;
    }
  }
}

@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.copy {
  @include mixins.fontLabel();
  margin-top: functions.rem(32px);

  &:before {
    content: "// ";
  }

  &:after {
    content: " //";
  }
}

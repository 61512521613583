$black: #000f1e;
$white: #ffffff;
$purple-lighter: #6e39fd;
$purple: #582dc9;
$green: #0fc28c;
$orange: #ff5d09;
$mid-grey: #a0a0a0;
$light-grey: #f2f2f2;
$dark-grey: #4b5861;
$grey: #5f6f7a;
$background: #f2f2f2;

@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

// navigation

@mixin heightNavigation() {
  height: functions.rem(variables.$height-navigation-mobile);
  @include breakpoints.regular() {
    height: functions.rem(variables.$height-navigation-desktop);
  }
}

// spacer

@mixin spacerExtraSmall($property) {
  #{$property}: functions.rem(variables.$spacer-mobile-xs);
  @include breakpoints.regular() {
    #{$property}: functions.rem(variables.$spacer-desktop-xs);
  }
}

@mixin spacerSmall($property) {
  #{$property}: functions.rem(variables.$spacer-mobile-s);
  @include breakpoints.regular() {
    #{$property}: functions.rem(variables.$spacer-desktop-s);
  }
}

@mixin spacerMedium($property) {
  #{$property}: functions.rem(variables.$spacer-mobile-m);
  @include breakpoints.regular() {
    #{$property}: functions.rem(variables.$spacer-desktop-m);
  }
}

@mixin spacerMediumDouble($property) {
  #{$property}: functions.rem(variables.$spacer-mobile-m * 2);
  @include breakpoints.regular() {
    #{$property}: functions.rem(variables.$spacer-desktop-m * 2);
  }
}

@mixin spacerLarge($property) {
  #{$property}: functions.rem(variables.$spacer-mobile-l);
  @include breakpoints.regular() {
    #{$property}: functions.rem(variables.$spacer-desktop-l);
  }
}

// font

@mixin fontHeadingLarge() {
  font-family: "founders-grotesk-regular";
  font-weight: normal;

  font-size: functions.rem(variables.$font-size-mobile-heading-large);
  line-height: functions.rem(variables.$line-height-mobile-heading-large);
  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-heading-large);
    line-height: functions.rem(variables.$line-height-desktop-heading-large);
  }
}

@mixin fontHeadingSmall() {
  font-family: "founders-grotesk-semibold";
  font-weight: normal;

  font-size: functions.rem(variables.$font-size-mobile-heading-small);
  line-height: functions.rem(variables.$line-height-mobile-heading-small);
  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-heading-small);
    line-height: functions.rem(variables.$line-height-desktop-heading-small);
  }
}

@mixin fontSubhead() {
  font-size: functions.rem(variables.$font-size-mobile-subhead);
  line-height: functions.rem(variables.$line-height-mobile-subhead);
  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-subhead);
    line-height: functions.rem(variables.$line-height-desktop-subhead);
  }
}

@mixin fontBodyLarge() {
  font-family: "founders-grotesk-regular";
  font-weight: normal;

  font-size: functions.rem(variables.$font-size-mobile-body-large);
  line-height: functions.rem(variables.$line-height-mobile-body-large);
  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-body-large);
    line-height: functions.rem(variables.$line-height-desktop-body-large);
  }
}

@mixin fontBodySmall() {
  font-family: "founders-grotesk-regular";
  font-weight: normal;

  font-size: functions.rem(variables.$font-size-mobile-body-small);
  line-height: functions.rem(variables.$line-height-mobile-body-small);
  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-body-small);
    line-height: functions.rem(variables.$line-height-desktop-body-small);
  }
}

@mixin fontLabel() {
  font-family: "JetBrains Mono";
  font-weight: 500;
  text-transform: uppercase;

  font-size: functions.rem(variables.$font-size-mobile-label);
  line-height: functions.rem(variables.$line-height-mobile-label);

  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-label);
    line-height: functions.rem(variables.$line-height-desktop-label);
  }
}

@mixin fontSubheadInline() {
  font-size: functions.rem(variables.$font-size-mobile-subhead-inline);
  line-height: functions.rem(variables.$line-height-mobile-subhead-inline);
  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-subhead-inline);
    line-height: functions.rem(variables.$line-height-desktop-subhead-inline);
  }
}

@mixin fontQuote() {
  font-size: functions.rem(variables.$font-size-mobile-quote);
  line-height: functions.rem(variables.$line-height-mobile-quote);
  @include breakpoints.regular() {
    font-size: functions.rem(variables.$font-size-desktop-quote);
    line-height: functions.rem(variables.$line-height-desktop-quote);
  }
}

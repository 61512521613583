@use "styles/colors";
@use "styles/mixins";
@use "styles/functions";

.listJobs {
  @include mixins.spacerSmall(margin-bottom);

  padding: 0;
  list-style: none;
}

.listJobsItem {
  margin: 0;

  border-bottom: 1px solid rgba(colors.$black, 0.2);
  &:first-child {
    border-top: 1px solid rgba(colors.$black, 0.2);
  }
}

.noJobsFound {
  font-family: "JetBrains Mono";
  font-weight: 500;
  font-style: normal;

  font-size: functions.rem(16px);
}

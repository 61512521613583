@use "styles/colors";
@use "styles/variables";
@use "styles/mixins";
@use "styles/breakpoints";
@use "styles/functions";

.container {
  @include mixins.spacerLarge(margin-bottom);
  color: colors.$white;
}

.containerOnDarkBackground {
  @extend .container;
  margin-bottom: 0;
  background-color: colors.$black;
}

.banner {
  display: flex;
  flex-direction: column;
  padding: functions.rem(28px) functions.rem(24px);

  background-color: colors.$purple;
  border-radius: variables.$rounded-8;
}

.link {
  cursor: pointer;
}

.heading {
  white-space: pre-line;
  font-family: "founders-grotesk-regular";
  font-weight: normal;

  font-size: functions.rem(variables.$font-size-mobile-heading-small);
  line-height: functions.rem(variables.$line-height-mobile-heading-small);

  width: 100%;
  margin-bottom: functions.rem(24px);
}

.richtext {
  width: 100%;
  font-size: functions.rem(18px);
  line-height: functions.rem(26px);

  a {
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: currentColor;
    background: none;
    border: none;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@include breakpoints.regular() {
  .banner {
    padding: functions.rem(44px) functions.rem(40px);

    flex-direction: row;
    border-radius: variables.$rounded-16;
  }

  .heading {
    font-size: functions.rem(variables.$font-size-desktop-heading-small);
    line-height: functions.rem(variables.$line-height-desktop-heading-small);
    margin-bottom: auto;
  }

  .richtext {
    padding-right: functions.rem(200px);

    font-size: functions.rem(28px);
    line-height: functions.rem(40px);
  }
}

@use "styles/colors";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/functions";

.container {
  background-color: rgba(colors.$white, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  height: functions.rem(160px);
  border-radius: functions.rem(variables.$rounded-16);
  margin-bottom: functions.rem(24px);
}

.logo {
  width: functions.rem(142px);
  height: functions.rem(24px);
  fill: colors.$white;
}

@include breakpoints.regular() {
  .container {
    margin-bottom: functions.rem(32px);
  }
}

@use "styles/colors";

.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;

  background-color: colors.$light-grey;
}

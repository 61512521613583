@use "styles/colors";
@use "styles/mixins";
@use "styles/functions";
@use "styles/breakpoints";

.container {
  @include mixins.spacerLarge(padding-bottom);
}

.divider {
  @include mixins.spacerSmall(margin-bottom);
}

.heading {
  @include mixins.spacerMedium(padding-bottom);
}

.list {
  margin-top: functions.rem(48px);
  padding: 0;
  list-style: none;
}

.listItem {
  margin: 0;
}

.listJobs {
  @include mixins.spacerSmall(margin-bottom);

  padding: 0;
  list-style: none;
}

.listJobsItem {
  margin: 0;

  border-bottom: 1px solid rgba(colors.$black, 0.2);
  &:first-child {
    border-top: 1px solid rgba(colors.$black, 0.2);
  }
}

@include breakpoints.regular() {
  .list {
    margin-top: 0;
  }
}

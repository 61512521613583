@use "styles/mixins";
@use "styles/functions";
@use "styles/breakpoints";

.text {
  @include mixins.fontLabel();
  display: block;
  margin-bottom: functions.rem(30px);

  &:before {
    content: "// ";
    display: inline;
  }
}

@include breakpoints.regular() {
  .text {
    margin-bottom: functions.rem(12px);
  }
}

@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.container {
  width: 100%;
  overflow-x: scroll;
  cursor: grab;

  // hide scrollbar
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
}

@use "styles/colors";
@use "styles/variables";
@use "styles/mixins";
@use "styles/functions";
@use "styles/breakpoints";

.container {
  @include mixins.spacerLarge(margin-bottom);
  width: 100%;
}

.divider {
  @include mixins.spacerSmall(margin-bottom);
}

.scrollerWrapper {
  @include mixins.spacerMedium(margin-top);
  @include mixins.spacerMedium(margin-bottom);
}

.items {
  display: flex;
  flex-wrap: nowrap;
  gap: functions.rem(8px);
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;

  > * {
    flex-shrink: 0;
    flex-grow: 0;
  }

  &:after {
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    display: block;
    content: "";
    height: 1px;
    width: calc(var(--content-offset) - #{functions.rem(8px)}); // - gap width
  }
}

@include breakpoints.regular() {
  .items {
    gap: functions.rem(16px);

    > *:nth-child(2n) {
      margin-top: functions.rem(90px);
    }

    &:after {
      width: calc(var(--content-offset) - #{functions.rem(16px)}); // - gap width
    }
  }
}

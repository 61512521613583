$base-font-size: 16px;

$content-width: 1360px;
$content-max-width: 1440px;

$height-navigation-mobile: 72px;
$height-navigation-desktop: 96px;

$spacer-mobile-xs: 16px;
$spacer-mobile-s: 24px;
$spacer-mobile-m: 56px;
$spacer-mobile-l: 80px;

$spacer-desktop-xs: 32px;
$spacer-desktop-s: 40px;
$spacer-desktop-m: 80px;
$spacer-desktop-l: 120px;

$rounded-4: 4px;
$rounded-8: 8px;
$rounded-16: 16px;
$rounded-20: 20px;
$rounded-40: 40px;
$rounded-100: 100px;

$font-size-desktop-heading-large: 80px;
$font-size-desktop-heading-small: 40px;
$font-size-desktop-subhead: 24px;
$font-size-desktop-body-large: 28px;
$font-size-desktop-body-small: 18px;
$font-size-desktop-label: 14px;
$font-size-desktop-subhead-inline: 18px;
$font-size-desktop-quote: 20px;

$line-height-desktop-heading-large: 80px;
$line-height-desktop-heading-small: 40px;
$line-height-desktop-subhead: 32px;
$line-height-desktop-body-large: 32px;
$line-height-desktop-body-small: 24px;
$line-height-desktop-label: 16px;
$line-height-desktop-subhead-inline: 24px;
$line-height-desktop-quote: 24px;

$font-size-mobile-heading-large: 40px;
$font-size-mobile-heading-small: 32px;
$font-size-mobile-subhead: 20px;
$font-size-mobile-body-large: 20px;
$font-size-mobile-body-small: 16px;
$font-size-mobile-label: 14px;
$font-size-mobile-subhead-inline: 16px;
$font-size-mobile-quote: 20px;

$line-height-mobile-heading-large: 40px;
$line-height-mobile-heading-small: 32px;
$line-height-mobile-subhead: 24px;
$line-height-mobile-body-large: 24px;
$line-height-mobile-body-small: 20px;
$line-height-mobile-label: 16px;
$line-height-mobile-subhead-inline: 20px;
$line-height-mobile-quote: 24px;

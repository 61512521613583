@use "styles/colors";
@use "styles/variables";
@use "styles/mixins";
@use "styles/functions";
@use "styles/breakpoints";

.container {
  @include mixins.spacerLarge(margin-bottom);
}

.divider {
  @include mixins.spacerSmall(margin-bottom);
}

.columns {
  margin-bottom: 0;
}

.assets {
  position: relative;
  @include mixins.spacerLarge(margin-top);
}

.canvas {
  position: absolute;
  top: functions.rem(-60px);
  left: 0;
  width: functions.rem(200px);
}

.richtext {
  a {
    text-decoration: underline;
    color: colors.$purple;
  }
  p {
    margin-bottom: functions.rem(24px);
  }

  ul,
  ol {
    margin-bottom: functions.rem(24px);
  }

  strong {
    font-family: "founders-grotesk-semibold";
  }

  em {
    font-family: "founders-grotesk-regular-italic";
  }
}

@include breakpoints.regular() {
  .canvas {
    top: functions.rem(-80px);
    width: auto;
  }
}

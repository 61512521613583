@use "styles/functions";
@use "styles/variables";
@use "styles/colors";
@use "styles/mixins";
@use "styles/breakpoints";

.container {
  display: flex;
  gap: functions.rem(16px);
}

.pagination {
  list-style: none;
  padding: 0;
  display: flex;
  gap: functions.rem(16px);
}

.button {
  @include mixins.fontLabel();

  padding: 0;
  position: relative;
  display: block;
  width: functions.rem(32px);
  height: functions.rem(32px);
  cursor: pointer;
  color: colors.$black;
  background-color: colors.$light-grey;
  border: 0;
  border-radius: variables.$rounded-4;

  &:hover {
    color: colors.$white;
    background-color: colors.$green;
  }

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: functions.rem(32px);
    height: functions.rem(32px);
    stroke: currentColor;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: colors.$black;
    background-color: colors.$light-grey;
  }
}

.buttonActive {
  @extend .button;

  color: colors.$white;
  background-color: colors.$purple;
}

@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.member {
  width: 100%;
}

.imageWrapper {
  margin-bottom: functions.rem(12px);

  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-color: rgba(colors.$white, 0.05);
  border-radius: variables.$rounded-16;
  overflow: hidden;
  will-change: transform;
}

.name {
  margin-bottom: functions.rem(4px);
}

.jobtitle {
  @include mixins.fontLabel();
  color: colors.$dark-grey;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: functions.rem(8px);
}

.buttonLinkedin {
  color: colors.$white;

  margin-left: functions.rem(-2px);
  > svg {
    fill: currentColor;
    width: functions.rem(22px);
    height: functions.rem(22px);
  }
}

@include breakpoints.regular() {
  .footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: functions.rem(16px);
  }

  .buttonLinkedin {
    color: colors.$white;
    margin-top: functions.rem(6px);
    margin-right: functions.rem(-3px);
    > svg {
      fill: currentColor;
      width: functions.rem(26px);
      height: functions.rem(26px);
    }
  }
}

@use "styles/colors";
@use "styles/mixins";
@use "styles/functions";
@use "styles/breakpoints";

.container {
  @include mixins.spacerSmall(padding-top);
  @include mixins.spacerMedium(padding-bottom);

  background-color: colors.$white;
  width: 100%;
}

.eyebrow {
  margin-bottom: functions.rem(52px);
}

.marqueeWrapper {
  width: 100%;
}

.list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.listItem {
  overflow: hidden;
  position: relative;
  display: inline-block;
  height: functions.rem(80px);
  margin-right: functions.rem(60px);
}

@include breakpoints.regular() {
  .container {
    @include mixins.spacerSmall(padding-top);
    @include mixins.spacerLarge(padding-bottom);
  }

  .eyebrow {
    @include mixins.spacerMedium(margin-bottom);
  }

  .listItem {
    height: functions.rem(80px);
    margin-right: functions.rem(100px);
  }
}

@use "styles/colors";
@use "styles/variables";
@use "styles/functions";
@use "styles/breakpoints";
@use "styles/mixins";

.container {
  @include mixins.spacerMedium(margin-top);
  @include mixins.spacerMedium(margin-bottom);
}

.content {
  display: flex;
  flex-direction: column;
}

.heading {
  width: 100%;
}

.details {
  @include mixins.fontBodyLarge();

  margin-top: functions.rem(44px);

  width: functions.rem(560px);
  flex-grow: 0;
  flex-shrink: 0;
}

.phone {
  > a {
    display: flex;
    gap: functions.rem(16px);

    > svg {
      width: functions.rem(22px);
      height: functions.rem(22px);
    }
  }
  margin-bottom: functions.rem(24px);
}

.mail {
  > a {
    display: flex;
    gap: functions.rem(16px);

    > svg {
      width: functions.rem(22px);
      height: functions.rem(22px);
    }
  }
  margin-bottom: functions.rem(24px);
}

.address {
  display: flex;
  gap: functions.rem(16px);
  white-space: pre-line;

  > svg {
    width: functions.rem(22px);
    height: functions.rem(22px);
  }
}

@include breakpoints.regular() {
  .content {
    flex-direction: row;
  }

  .phone {
    margin-bottom: functions.rem(30px);

    > a {
      > svg {
        width: functions.rem(32px);
        height: functions.rem(32px);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mail {
    margin-bottom: functions.rem(30px);

    > a {
      > svg {
        width: functions.rem(32px);
        height: functions.rem(32px);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .address {
    > svg {
      width: functions.rem(32px);
      height: functions.rem(32px);
    }
  }
}

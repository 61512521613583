@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.reference {
  // color: colors.$white;
  width: 100%;
  padding: functions.rem(25px) functions.rem(24px) functions.rem(3px);
  background-color: rgba(colors.$black, 0.05);
  border-radius: variables.$rounded-16;

  .contact {
    color: colors.$mid-grey;
  }

  &.isInverted {
    background-color: rgba(colors.$white, 0.05);

    .contact {
      color: colors.$white;
    }
  }
}

.referenceJobDetail {
  @extend .reference;
  color: colors.$black;
  background-color: colors.$white;

  .contact {
    color: colors.$mid-grey;
  }
}

.quote {
  font-family: "founders-grotesk-regular-italic";
  font-weight: normal;
  font-size: functions.rem(20px);
  line-height: functions.rem(24px);
  margin-bottom: functions.rem(28px);
}

.contact {
  margin-bottom: functions.rem(28px);
  display: flex;
  align-items: center;
}

.thumbnail {
  position: relative;
  border-radius: variables.$rounded-100;
  overflow: hidden;
  margin-right: functions.rem(16px);
  width: functions.rem(64px);
  height: functions.rem(64px);
  background-color: colors.$purple;
  flex-grow: 0;
  flex-shrink: 0;
  will-change: transform;
}

.face {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  font-family: "JetBrains Mono";
  font-weight: 500;
  font-style: normal;

  font-size: functions.rem(18px);
  color: colors.$white;
}

.details {
  font-family: "founders-grotesk-regular";
  font-weight: normal;
  font-size: functions.rem(16px);
  line-height: functions.rem(20px);
}

.button {
  @include mixins.fontLabel();
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: functions.rem(16px) 0;
  background: none;
  border: none;
  border-top: 2px solid colors.$black;
  color: currentColor;
  text-align: left;
  cursor: pointer;

  > svg {
    stroke: currentColor;
    width: functions.rem(32px);
    height: functions.rem(32px);
  }
}

.buttonActive {
  @extend .button;

  > svg {
    stroke: currentColor;
    transform: rotate(180deg);
  }
}

.review {
  @include mixins.fontBodySmall();
  color: colors.$dark-grey;
  margin-bottom: functions.rem(37px);
}

@include breakpoints.regular() {
  .reference {
    padding: functions.rem(40px) functions.rem(40px) functions.rem(3px);
  }

  .quote {
    font-size: functions.rem(32px);
    line-height: functions.rem(32px);
    margin-bottom: functions.rem(28px);
  }

  .details {
    font-size: functions.rem(18px);
    line-height: functions.rem(24px);
  }

  .button {
    padding: functions.rem(20px) 0;
  }

  .thumbnail {
    margin-right: functions.rem(24px);
  }
}

@use "styles/colors";
@use "styles/mixins";
@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.container {
  @include mixins.spacerSmall(padding-top);
  @include mixins.spacerLarge(padding-bottom);

  color: colors.$white;
  background-color: colors.$black;
}

.columns {
  @include mixins.spacerMedium(margin-bottom);
}

.list {
  padding: 0;
  list-style: none;

  display: inline-grid;
  width: 100%;

  grid-template-columns: repeat(2, 1fr);
  gap: functions.rem(16px);
}

.listItem {
  position: relative;
  margin: 0;
  width: 100%;
  background-color: rgba(colors.$white, 0.05);
  border-radius: variables.$rounded-16;
  padding-bottom: 100%;
}

.company {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@include breakpoints.regular() {
  .list {
    grid-template-columns: repeat(3, 1fr);
    gap: functions.rem(16px);
  }
}

@use "styles/mixins";
@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.loader {
  font-family: "JetBrains Mono";
  font-weight: 500;
  font-style: normal;

  font-size: functions.rem(16px);
}

@use "styles/colors";
@use "styles/mixins";
@use "styles/functions";

.divider {
  width: 100%;
  height: 1px;
  // background-color: colors.$light-grey;
  background-color: rgba(colors.$black, 0.2);
}

.container {
  width: 100%;
  @include mixins.spacerMedium(margin-bottom);
}

.content {
  min-height: functions.rem(400px);
}

.divider {
  @include mixins.spacerSmall(margin-bottom);
}

.heading {
  @include mixins.spacerMedium(padding-bottom);
}

.list {
  padding: 0;
  list-style: none;
}

.listItem {
  margin: 0;
}

.form {
  width: 100%;
}

.listJobs {
  @include mixins.spacerSmall(margin-bottom);

  padding: 0;
  list-style: none;
}

.listJobsItem {
  margin: 0;

  border-bottom: 1px solid rgba(colors.$black, 0.2);
  &:first-child {
    border-top: 1px solid rgba(colors.$black, 0.2);
  }
}

.containerButtonPill {
  display: flex;
  flex-wrap: wrap;
  margin: functions.rem(32px) 0;
  min-height: functions.rem(24px);
  gap: functions.rem(9px);
}

@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.container {
  width: 100%;
}

@include breakpoints.regular {
  .container {
    display: flex;

    > * {
      width: 100%;
    }

    > *:first-child {
      width: functions.rem(230px);
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .containerWide {
    display: flex;

    > * {
      width: 100%;
    }

    > *:first-child {
      width: functions.rem(460px);
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}

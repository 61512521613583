@use "styles/mixins";
@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: none;
}

.copy {
  position: absolute;
  bottom: 320px;
  left: 0;

  font-size: 28px;
  font-family: "JetBrains Mono";
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;

  color: colors.$white;
  z-index: 1;

  white-space: pre-line;
}

.line {
  display: block;
  position: relative;

  height: 40px;
}

.char {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;

  line-height: 40px;

  text-align: center;

  background-color: colors.$black;
}

@include breakpoints.regular() {
  .container {
    display: block;
  }
}

@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.container {
  position: relative;
  width: functions.rem(327px);

  &:after {
    @include mixins.fontLabel();
    color: colors.$dark-grey;
    display: block;
    position: absolute;
    top: functions.rem(32px);
    left: functions.rem(32px);
  }

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      &:after {
        @if $i < 10 {
          content: "0#{$i}";
        } @else {
          content: "#{$i}";
        }
      }
    }
  }
}

.imageWrapper {
  position: relative;
  display: block;
  pointer-events: none;
  border-radius: variables.$rounded-20;
  overflow: hidden;
  margin-bottom: functions.rem(31px);
  will-change: transform;
}

.heading {
  margin-bottom: functions.rem(16px);
}

.richtext {
  font-family: "founders-grotesk-regular";
  font-size: functions.rem(16px);
  line-height: functions.rem(20px);

  width: functions.rem(241px);

  a {
    text-decoration: underline;
    color: colors.$purple;
  }
  p {
    margin-bottom: functions.rem(24px);
  }

  ul,
  ol {
    margin-bottom: functions.rem(24px);
  }

  strong {
    font-family: "founders-grotesk-semibold";
  }

  em {
    font-family: "founders-grotesk-regular-italic";
  }
}

@include breakpoints.regular() {
  .container {
    width: functions.rem(558px);
  }

  .imageWrapper {
    border-radius: variables.$rounded-40;
  }

  .richtext {
    font-size: functions.rem(18px);
    line-height: functions.rem(24px);

    width: functions.rem(443px);
  }
}

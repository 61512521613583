@mixin regular {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 1024px) {
    @content;
  }
}

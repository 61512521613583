@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";
@use "styles/variables";

@keyframes blink {
  0% {
    opacity: 0.4;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.4;
    transform: scale(0.5);
  }
}

.map {
  position: relative;
}

.dotWrapper {
  position: absolute;
}

.dot {
  position: absolute;
  top: functions.rem(-8px);
  left: functions.rem(-8px);
  width: functions.rem(16px);
  height: functions.rem(16px);

  background-color: colors.$white;
  border-radius: variables.$rounded-100;

  animation: blink 2s infinite;
}

.content {
  position: absolute;
  top: functions.rem(24px);
  bottom: functions.rem(24px);
  left: functions.rem(24px);
  right: functions.rem(24px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(14px);
  color: colors.$white;
}

.titleWrapper {
  align-self: flex-end;
}

.title {
  font-size: functions.rem(16px);
  line-height: functions.rem(16px);
}
.location {
  font-size: functions.rem(27px);
  line-height: functions.rem(27px);
}

.list {
  list-style: none;
  margin: functions.rem(12px) 0 0;
  padding: 0;

  font-size: functions.rem(16px);
  line-height: functions.rem(16px);
}

.button {
  display: inline-block;
  text-decoration: underline;
}

@include breakpoints.regular() {
  .content {
    top: functions.rem(40px);
    bottom: functions.rem(40px);
    left: functions.rem(40px);
    right: functions.rem(40px);
  }

  .list {
    margin: functions.rem(14px) 0 0;
  }

  .location {
    align-self: flex-end;
  }

  .button {
    &:hover {
      text-decoration: none;
    }
  }
}

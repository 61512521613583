@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.container {
  height: calc(100vh - #{functions.rem(variables.$height-navigation-mobile)});
  height: calc(
    var(--vh-orientation, 1vh) * 100 - #{functions.rem(variables.$height-navigation-mobile)}
  );
  min-height: functions.rem(400px);

  display: flex;
  text-align: center;
  align-items: center;
}

.heading {
  @include mixins.spacerSmall(margin-bottom);

  font-family: "JetBrains Mono";
  font-weight: 400;
  font-style: normal;
  font-size: functions.rem(40px);

  color: colors.$purple;
}

.paragraph {
  @include mixins.fontBodyLarge();
}

@include breakpoints.regular() {
  .container {
    min-height: 500px;
    height: calc(100vh - #{functions.rem(variables.$height-navigation-desktop)});
    height: calc(
      var(--vh-orientation, 1vh) * 100 - #{functions.rem(variables.$height-navigation-desktop)}
    );
  }

  .heading {
    @include mixins.spacerSmall(margin-bottom);

    font-size: functions.rem(80px);
  }
}

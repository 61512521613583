@use "styles/colors";
@use "styles/mixins";
@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.container {
  @include mixins.spacerSmall(padding-bottom);
  @include mixins.spacerSmall(padding-top);

  background-color: colors.$white;
  color: colors.$black;

  &.isInverted {
    background-color: colors.$black;
    color: colors.$white;
  }
}

.heading {
  @include mixins.spacerMedium(padding-bottom);
  white-space: pre-line;
}

.columns {
  padding: 0;
  list-style: none;
  display: flex;
  gap: functions.rem(16px);
}

.column {
  width: 100%;
}

.list {
  padding: 0;
  list-style: none;
}

.listItem {
  margin: 0 0 functions.rem(16px) 0;
}

@include breakpoints.regular() {
  .column {
    width: calc(33.333% - functions.rem(11px));

    &:first-child {
      margin-top: functions.rem(40px);
    }

    &:nth-child(2) {
      margin-top: functions.rem(120px);
    }
  }
}

.error {
  padding: 0;
  list-style: none;
  display: flex;
  color: #a0a0a0;
  text-align: center;
  width: 100%;
}

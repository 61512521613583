@use "styles/colors";
@use "styles/variables";
@use "styles/mixins";
@use "styles/functions";
@use "styles/breakpoints";

.container {
  @include mixins.spacerLarge(padding-top);
  @include mixins.spacerMedium(padding-bottom);
}

@include breakpoints.regular() {
  .content {
    padding-right: functions.rem(230px);
  }
}
